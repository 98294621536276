
            @import 'src/styles/mixins.scss';
        
html,
body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
        "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 300;
    color: unset;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 0;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
.tspa-tooltip {
    display: inline-block;
    cursor: pointer;
    color: blue;
    border-bottom: 1px solid blue;
    position: relative;
    span {
        display: none;
        position: absolute;
        background-color: black;
        color: #fff;
        border-radius: 5px;
        padding: 10px;
        min-width: 150px;
        transform: translateX(-50%) translateY(-100%);
        left: 50%;
        top: -5px;
        line-height: 1.4;
        font-size: 9px;
    }
    &:hover {
        &:before,
        &:after,
        span {
            display: block;
        }
    }
    &:after {
        width: 9px;
        height: 9px;
        background-color: black;
        display: none;
        transform: translateX(-50%) translateY(-22px) rotate(45deg);
        left: 50%;
        content: "";
        position: absolute;
    }
}
.tcpa-Modal {
    display: inline-block;
    cursor: pointer;
    color: blue;
    border-bottom: 1px solid blue;
    > span {
        display: none;
    }
}
.withoutTS .successMsgWithOutTooltip {
    display: none !important;
}
.withTS .successMsgWithTooltip {
    display: none !important;
}
@layer components {
    .hide-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .horizontal-scroll {
        display: flex;
        overflow-x: scroll;
        @apply hide-scrollbar;
    }
    .horizontal-scroll-child {
        flex: 0 0 auto;
    }
    .absolute-v-center {
        top: 50%;
        transform: translateY(-50%);
    }
    .next-image {
        & > div {
            position: static !important;
            img {
                position: static !important;
                width: 100% !important;
                height: auto !important;
            }
        }
    }
}
.btnStyle {
    background-color: #4992e9;
    color: #fff !important;
    margin: 0 auto;
    display: block;
    width: fit-content;
    font-size: 20px;
    font-weight: 500;
    padding: 15px 46px;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
}
.btnStyle:hover {
    transition: all 0.3s ease-in-out;
    transform: translateY(-5px);
    text-decoration: none !important;
}

.offerListContainer {
    min-height: calc(100vh - 54px);
    padding: 0 10px;
    position: relative;
    background-color: #fff;

    .textBlock {
        h1 {
            font-size: 25px;
            font-weight: 700;
            margin: 0;
            line-height: 1.2;
            margin-bottom: 5px;
            @include sm {
                font-size: 40px;
            }
        }

        p {
            font-size: 16px;
            font-weight: 400;
            margin: 0;
            color: #515151;
            @include sm {
                font-size: 25px;
            }
        }
    }
}

.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 80%);
    }
    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.skeleton-text {
    width: 100%;
    height: 0.7rem;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
}

.skeleton-button {
    width: 100%;
    height: 50px;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
}

.skeleton-input {
    width: 100%;
    height: 30px;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
}

.skeleton-text__body {
    width: 75%;
}

.skeleton-footer {
    width: 30%;
}

/******************************** severalbrands.com style ***********************************/

.grecaptcha-badge {
    display: none !important;
}

/******************************** severalbrands.com style ***********************************/

.backdrop {
    background-color: rgba(black, 0.8) !important;

    div > main > div::before {
        z-index: -1;
    }

    position: fixed;
    inset: 0;

    header {
        -moz-user-select: none;
        -webkit-user-select: none;
        user-select: none;
        pointer-events: none;
        cursor: none;
        filter: brightness(40%);
    }

    animation: fade-in 0.1s ease-in-out;
}
@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.disable-scroll {
    height: 100% !important;
    overflow-y: hidden !important;
}

/********** EnduranceWarrantyProtect.com ********/
.dotsEnduranceWarrantyProtectStyle {
    :not(.slick-active) {
        button {
            &::before {
                color: #fff !important;
                font-size: 11px !important;
                opacity: 1 !important;
            }
            &:hover {
                &::before {
                    color: #ff870b !important;
                }
            }
        }
    }
    .slick-active {
        button {
            &::before {
                color: #ff870b !important;
                font-size: 11px !important;
                opacity: 1 !important;
            }
        }
    }
}
.removeWhiteBg {
    .offerListContainer {
        min-height: calc(100vh - 54px);
        padding: 0;
        position: static;
        background-color: transparent;
    }
}
/********** EnduranceWarrantyProtect.com ********/
