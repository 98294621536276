
            @import 'src/styles/mixins.scss';
        
.blogPost {
    .btn {
        background-color: #1693f0;
        font-weight: 500;
        color: white !important;
        border-radius: 8px;
        padding: 10px 15px;
        cursor: pointer;
        margin: 0 auto;
        width: fit-content;
        display: block;
        min-width: 300px;
        text-align: center;
        &:hover {
            text-decoration: none;
            background-color: #68beff;
            transition: all 0.1s ease-in-out;
        }
    }
    a.btn {
        text-decoration: none;
    }
}
